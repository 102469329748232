import React, { useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const DocumentsCreate = props => {
    const classes = useStyles({});
    const [isLoading,setIsLoading] = useState(false);
    const initialValues = {
        calimOrdId: props.calimOrdId
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        setIsLoading(true);
        let m = moment(new Date());
        let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
        apiClient.UploadFileV1("imageUrl", formdata.imageUrl).then(res => {
            debugger
            let documentUrl = res;
            let data = {
                accountId: parseInt(props.accountId),
                imageTypeId: parseInt(props.imageTypeId), 
                imageLegacyName:formdata.imageUrl.File,
                typeId:formdata.typeId,
                licNo:formdata.licNo,
                isDefualt:formdata.isDefualt,
                isActive:formdata.isActive, 
                isVerified :formdata.isVerified, 
                url: documentUrl.url,
                imageSize:parseInt(documentUrl.filesize),
                createdBy: user,
                createdDate:  m.toISOString(),
                modifyBy: user,
                modifyDate:  m.toISOString(),
            }
            apiClient.addEntiyRecord("account-image-maps", data).then(res => {
                //console.log(res);
                setIsLoading(false);
                props.onClose();
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });

    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar loading={isLoading} label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">
                    
               {parseInt(props.imageTypeId)==115 &&  <ReferenceInput label="Image  Type"  
                    fullWidth={true} 
                    perPage={5000} source="typeId"
                    filter={{ type: 'ImgType' }}
                    reference="lookups" >
                    <SelectInput optionText="name"  validate={[required()]} variant="outlined" fullWidth={true} />
                </ReferenceInput>}


                {parseInt(props.imageTypeId)==114 &&  <ReferenceInput label="License  Type"  
                    fullWidth={true}
                    perPage={5000} source="typeId"
                    filter={{ type: 'LicType' }}
                    reference="lookups" >
                    <SelectInput optionText="name" validate={[required()]} variant="outlined" fullWidth={true} />
                </ReferenceInput>}
                {parseInt(props.imageTypeId)==114 && 
                 <TextInput  source="licNo" label="License No " validate={[required()]}
                  fullWidth={true} />}

                <FileInput fullWidth={ true } source="imageUrl" label="Image"> 
                <ImageField source="imageUrl" title="File" />  
                </FileInput>  
                
                <BooleanInput source="isDefault"  label="Is Default" initialValue={false} />
                <BooleanInput source="isVerified"  label="Is Verified" initialValue={false} /> 
                <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} />
                
            </SimpleForm>
        </Create>
    );
}
export default DocumentsCreate;