import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Description"
      source="description~like"
      alwaysOn
    />
  </Filter>
);
const DisplayImage = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <div style={{width:"50px",height:"50px"}}> 
        <img                    src={record[source]}
                      width="100%"
                      height="100%"
                      
        /> </div>
    )
  else {
    return null
  }
}
export const UserEnqList = (props) => {
 
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate =false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="User Enquiry List" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        
        <TextField source="code" label="Enquiry No " /> 
        <ReferenceField label="Product Group" source="productGroupId" 
                  reference="product-groups" link={false}>
                  <TextField source="groupName" />
                </ReferenceField>
        <TextField source="description" label="Description " />
        <DisplayImage label="Image 1" source="url"/> 
        <DisplayImage label="Image 2" source="url1"/>   
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="ModifyBy" reference="users" label="Modify By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifyDate" label="Modify Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
