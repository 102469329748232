import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  FileInput, FileField, 
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const ActivityEdit = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  
  
  return (
    <Edit {...propsObj}  title="Partner Home Sections Edit">
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props}  />} variant="outlined" >
      
      <TextInput source="name" validate={[required()]} label="English Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="nameHindi" validate={[required()]} label="Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       
        
       <BooleanInput source="isActive" validate={[required()]}  label="Active" />
     </SimpleForm>
    </Edit>
  );
};
