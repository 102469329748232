import React, { useCallback } from 'react';
import moment from 'moment';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    FormDataConsumer,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import Addressfields from "./Addressfields";
 
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const AccountAddressFields = props => {
    
    const classes = useStyles({});
    const handleSubmit = async (formdata) => {
         console.log(formdata);
          
        // let data = {
        //     "configId": parseInt(props.configId),
        //     "docType": formdata.docType,
        //     "docValue": parseInt(formdata.docValue),
        //     "isActive":true,
            
        // }
        let m = moment(new Date());
        let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }

        let data = {
            accountId: parseInt(props.accountId),
            addressType:  parseInt(formdata.addressType),
            address1: formdata.address1,
            address1Hindi: formdata.address1Hindi,
            address2: formdata.address2,
            address2Hindi: formdata.address2Hindi,
            cityId: parseInt(formdata.cityId),
            stateId:  parseInt(formdata.stateId),
            
            countryId:  parseInt(formdata.countryId),
            zipCode: formdata.zipCode,
            createdBy: user,
            createdDate:  m.toISOString(),
            modifyBy: user,
            modifyDate:  m.toISOString(),
            isDefault: true,
            isActive: true
          }
        
        
        
        
        apiClient.addEntiyRecord("account-details", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined">
                      <ReferenceInput label="Address Type"  
            fullWidth={true}
                    perPage={5000} source="addressType"
                    filter={{ type: 'ACAT' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} />
                </ReferenceInput>
                <TextInput validate={[required()]}  source="address1" label="Address 1" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput validate={[required()]}  source="address1Hindi" label="Address 1 Hindi" fullWidth={true}  formClassName={classes.last_inline_input} />
                <TextInput   source="address2" label="Address 2" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput   source="address2Hindi" label="Address 2 Hindi" fullWidth={true} formClassName={classes.last_inline_input} />  
                <FormDataConsumer>
              {formDataProps => (
                <Addressfields {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput   source="zipCode" label="Zip Code" fullWidth={true} /> 
            <BooleanInput label="Is Default" source="isDefault" fullWidth={true} initialValue={false} />
            <BooleanInput label="Is Active" source="isActive" fullWidth={true} initialValue={false}  />                  
            </SimpleForm>
        </Edit>
    );
}
export default AccountAddressFields;