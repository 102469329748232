import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

const Companyfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div style={{width:"100%"}} >

<ReferenceInput variant="outlined" label="Company" source="compId"
                reference="configs"
                sort={{ field: 'mconame', order: 'ASC' }}
                fullWidth={true}
                 perPage={5000} 
                formClassName={classes.one_four_input}  
                onChange={(v) => {
                    form.change("compCostCenterId", 0);
                }} >
                <SelectInput optionText="mconame" />

            </ReferenceInput>
            <ReferenceInput variant="outlined" label="Cost Center" source="compCostCenterId"
                reference="company-cost-centers"
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ "companyId": formData.compId}}
                 perPage={5000} 
                 fullWidth={true}
                formClassName={classes.two_four_input}  
                onChange={(v) => {
                    form.change("compBranchId", 0);
                }} >
                <SelectInput optionText="name"/>

            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Warehouse" source="compBranchId"
                reference="company-branches" perPage={500}
                filter={{ "compCostCenterId": formData.compCostCenterId }}
                formClassName={classes.three_four_input}  
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true}
                onChange={(v) => {
                    form.change("compFacilityId", 0);
                }}
                >
                <SelectInput  optionText="name" />
            </ReferenceInput>


            <ReferenceInput variant="outlined" label="Facility" source="compFacilityId"
                 perPage={500}
                reference="company-facilities"
                filter={{ "compBranchId": formData.compBranchId }}
                fullWidth={true}
                formClassName={classes.last_four_input}  
                sort={{ field: 'name', order: 'ASC' }}
                >
                <SelectInput  optionText="name" />
            </ReferenceInput>
        </div>
    )
}
export default Companyfields;
