import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  DateTimeField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";
const DisplayImage = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <div style={{width:"50px",height:"50px"}}> 
        <img                    src={record[source]}
                      width="100%"
                      height="100%"
                      
        /> </div>
    )
  else {
    return null
  }
}
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="English Name"
      source="engName~like"
      alwaysOn
    />
    
  </Filter>
);
export const NotifiactionList = (props) => {
 
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Notification Type List" filters={<Filters />}  >
      <Datagrid rowClick={false}>
        {propsObj.hasEdit && <EditButton />}

        
        <TextField source="name" label="English Name " />
        <TextField source="nameHindi" label="Hindi Name " />
        <DateField  source="triggerDatetime"  label="Trigger Date time" showTime showDate   locales="fr-FR"/>  
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifydate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
