import React,{useState,useCallback} from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  NumberInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceField,
  useRefresh,
  ImageField,
  ImageInput,
  FormDataConsumer

} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import DocumentEdit from './docs/DocumentEdit';
import DetailCreate from './docs/DetailCreate';
import { useStyles } from "../../formStyles";
import CustomSelectInput from "./CustomSelectInput";
const useImageFieldStyles = makeStyles(theme => ({
  image: { // This will override the style of the <img> inside the <div>
      width: 200,
      height: 200,
  }
}))
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));



export const ProductDiseaseEdit = (props) => {
  const myclasses = myStyles({});
  const classes = useStyles({});
  const imageFieldClasses = useImageFieldStyles();
  const [isadd, setIsAdd] = useState(false);
  const refresh = useRefresh();
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const CustomField = (props) => {
    let { record, source } = props;
    
    if (record.typeId==121 && source && record[source] && record[source].length > 0)
      return (
        <a href={record[source]} target="_blank" >View Video</a> 
      )
    else if (record.typeId==122 && source && record[source] && record[source].length > 0)
      return (
        <img src={record[source]} height={150} width={150} /> 
      )
    else {
      return null
    }
  }
  const SectionHeading = (props) => {
    const { caption } = props;
    return (
      <div className="heading">
        <h3>{caption}</h3>
      </div>
    )

  } 
  const onCancel = () => {
   setIsAdd(false);
  }
  const handleClose = useCallback(() => {
    onCancel();
  }, []);
  const onCloseDocumentForm = () => {
    setIsAdd(false);
   
    refresh();
  }
  const SecondaryButton = (props) => {
    const { caption, onClick } = props
    return (
      <div style={{ flex: 1 }}>
        <Button variant="contained" color="secondary" style={{ marginRight: "10" }} onClick={onClick}>{caption}</Button>
      </div>
    )
  } 
  const DisplayFileLink = (props) => {
    let { record, source } = props;
    if (source && record[source] && record[source].length > 0)
      return (
        <a href={record[source]}>View File</a>
      )
    else {
      return null
    }
  }
  return (
    <React.Fragment>
    <Edit {...propsObj}  title="Content Page Edit">
      {/* <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined"> */}
      <TabbedForm
          
          redirect="list" variant="outlined" toolbar={<FormToolbar {...propsObj} showDelete={false} />}>
      <FormTab label="Basic Details">

      <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="product-groups" selectedText={""} source={"productGroupId"}  optionText={"groupName"} label={"Product Group"} fullWidth={true} {...formDataProps} 
                
                dependentFields={["productId"]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="products"   selectedText={""} source={"productId"}  optionText={"productName"} label={"Product"} fullWidth={true} {...formDataProps} 
                filters={[{"productGroupId":formDataProps.productGroupId}]}
                />
              )}
            </FormDataConsumer>
          
      {/* <ReferenceInput  perPage={2000} label="Product Group" source="productGroupId" reference="product-groups" 
      fullWidth={true} sort={{ field: 'groupName', order: 'ASC' }}>
                    <SelectInput optionText="groupName" validate={required()}  disabled={false}/>
      </ReferenceInput>  */}
      

      <ReferenceInput  perPage={2000} label="Stage" source="stageId" reference="stages" 
      fullWidth={true} sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" validate={required()}  disabled={false}/>
      </ReferenceInput> 
      
       
      
       <TextInput source="diseaseName" validate={[required()]} label="Disease Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="diseaseNameHindi" validate={[required()]} label="Disease Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       <TextInput multiline source="moreDetails" label="English Desciption" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput multiline source="moreDetailsHindi"  label="Hindi Desciption" fullWidth={true}  formClassName={classes.last_inline_input}/> 
       <ImageField source="url" title="English Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="English "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>

         <ImageField source="urlHindi" title="Hindi Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="hindiUpurl" 
              formClassName={classes.last_inline_input}
              label="Hindi "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="hindiUpurl" title="Icon" />
            </ImageInput>
      
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
        </FormTab>
        <FormTab label="Solution"> 
            <SectionHeading caption="Solution" />
            <SecondaryButton formClassName="secondary-action-btn" caption="Add Solution" onClick={() => setIsAdd(true)} />
           
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              
              reference="product-diseases-sols"
              target="productDiseasesId">
              <Datagrid >
                {<CDeleteButton {...props} cellClassName="delete-column" />}                   
                <ReferenceField  perPage={2000} label="Soultion Product" source="productId" reference="products" link={false}
      >
                    <TextField source="productName" />
      </ReferenceField> 
                <TextField label="Name" source="name" />
                <TextField label="Name Hindi" source="nameHindi" />
                <TextField label="More Details" source="moreDetails" />
                <TextField label="More Details Hindi" source="moreDetailsHindi" />
                <TextField label="Active" source="isActive" /> 
                {/* <ImageField classes={imageFieldClasses}  label="" source="url" /> */}
                <CustomField classes={imageFieldClasses}  label="" source="url" />
                
                <CustomField classes={imageFieldClasses}  label="" source="urlHindi" />
              </Datagrid>
            </ReferenceManyField>            
          </FormTab>
      </TabbedForm>
    </Edit>
    

      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isadd} 
       >
 {isadd  && <DetailCreate
          onClose={() => { setIsAdd(false); refresh(); }}
          onCancel={onCancel}
          id={props.id}
          record={props.id}
          {...props} />
 }
        
      </Drawer>
    </React.Fragment>
  );
};
