import React,{useState,useEffect} from "react";
import * as apiClient from "../../common/apiClient";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  DateInput,ReferenceField,TextField,FormDataConsumer,useNotify,useRedirect
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { GridDetail } from './GridDetail';

export const ActivityEdit = (props) => {
  const [gridData, setGridData] = useState([]);
  const[isLoading,SetIsLoading]=useState(true)
  const notify = useNotify();
  const redirect = useRedirect();
  const handleChange = (indata) => { 
    setGridData(indata);
 }
 
useEffect(() => { 
  loadData()
}, []);


const loadData =  async() => {
  SetIsLoading(true)
  await apiClient.getdata_v1(`/sale-order-dtls?filter[offset]=0&filter[limit]=1000&filter[order]=orderId%20ASC&filter[where][orderId]=${props.id}`).then(res => { 
    
    setGridData(res); 
    SetIsLoading(false)
  }).catch(err => {
    console.log(err);
    SetIsLoading(false)
  });
}
  

  //   let data: any = {};
  //   data.id = parseInt(props.id);
  //   data.branchId=formdata.branchId;
  //   data.vendorId=formdata.vendorId;
  //   data.documentNo=formdata.documentNo;
  //   data.purchaseDate=formdata.purchaseDate;
  //   data.taxType=taxType; 
  //   data.remarks=formdata.remarks; 
  //   data.supplyDate=formdata.supplyDate; 
  //   data.paymentModeId=formdata.paymentModeId; 
  //   data.lineItems=[...gridData];
  //   // let m = moment(new Date());

  //   // data. modifyDate=  m.toISOString()
  //   data.createdBy = userId
  //   apiClient.addeditPurchaseOrder(data).then(res => {
  //     if (res[0].id > 0) {
  //       notify(res[0].remarks)
  //       redirect("/purchase-orders");
  //     }
  //     else {
  //       notify(res[0].remarks, "warning")

  //     }
  //     //console.log(res);
  //     //props.onClose();
  //   }).catch(err => {
  //     console.log(err);
  //   });
  ///}
  const handleSubmit = async (formdata) => {
    
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let formBody={
      userId:userId,
      orderId:props.id,
      data:[...gridData]
    }
    console.log(formBody);
    apiClient.edtiSaleOrder(formBody).then(res => {
      if (res[0].result) {
        notify(res[0].message)
        redirect("/sale-orders");
      }
      else {
        notify(res[0].message, "warning")

      }
      //console.log(res);
      //props.onClose();
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <Edit title="Sale Edit" {...props}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="orderNo" fullWidth={true} disabled /> 
        <DateInput source={"orderDate"} label={"Order Date"} disabled fullWidth={true} validate={[required()]} variant="outlined"  />         
        <ReferenceInput source="franchiseId" reference="accounts" label="Franchise"  fullWidth={true}    disabled link={false}>
                <SelectInput optionText="accountName" />
            </ReferenceInput>
            {!isLoading && gridData.length>0 && <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail {...formDataProps} taxType={0} gridData={gridData}  handleChange ={handleChange}/>
              )}
            </FormDataConsumer>}
        </SimpleForm>
      
    </Edit>
  );
};
