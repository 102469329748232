import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const DetailCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        calimOrdId: props.calimOrdId
    }
 const handleSubmit = async(formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        let userid = localStorage.getItem("userId"); 
        
        let data = {
            enqId: parseInt(props.enqId),   
            ask: "",
                reply: formdata.reply,
                 
              }
            
            
        
        data.createdBy = parseInt(userid);
        data.createdDate = m.toISOString();
        data.modifyBy = parseInt(userid);
        data.modifyDate = m.toISOString();
        apiClient.addEntiyRecord("user-product-group-enq-dtls", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">
                <TextInput multiline source="reply" label="Reply" fullWidth={ true } validate={[required()]}  />   
                
                
            </SimpleForm>
        </Create>
    );
}
export default DetailCreate;