import React, { useState, useCallback, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CallSplit from '@material-ui/icons/CallSplit';
import * as apiClient from '../../common/apiClient';
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
  Edit, SimpleForm, TextInput, RichTextField, Button,
  ReferenceInput, SelectInput, EditButton, NumberField,
  DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
  ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import ProductAttributes from './ProductAttributes';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 300,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));


export const GridDetail = ({ formData, ...rest }) => {
  const form = useForm();
  const myclasses = myStyles({});
  const [gridData, setGridData] = useState([]);
  const [isAddEditAttribute, setIsAddEditAttribute] = useState(false);
  const [selectRecord, setSelectRecord] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const classes = containerStyles({});  
  const getproductdata = async (productId) => {
    let result = { name: "", hsnGroupId: 0, taxPer: 0, price: 0 };
    await apiClient.getproductdata(productId).then(res => {
      result.name = res.productName;
      result.hsnGroupId = res.hsnGroupId;
      result.taxPer = res.taxPer;
      result.price = res.price;

    }).catch(err => {
      console.log(err);
    });
    return result;
  } 
  const gethsnData = async (hsnId) => {
    let result = "";
    await apiClient.gethsnData(hsnId).then(res => {
      result = res.name;
    }).catch(err => {
      console.log(err);
    });
    return result;
  } 
  const getlineTotal = (index) => {
    debugger
    let result = 0;
    let qty = index.qty;
    let price = index.price;
    if (isNaN(parseInt(index.qty))) {
      qty = 0;
    }
    if (isNaN(parseFloat(index.price))) {
      price = 0;
    }
    result = parseFloat(index.price) * parseInt(index.qty)
    if (isNaN(result)) {
      result = 0;
    }

    return result;
  }
  const getlineTaxTotal = (index) => {
    debugger
    let result = 0;
    let qty = index.qty;
    let price = index.price;
    let taxPer = index.taxPer;
    if (isNaN(parseInt(index.qty))) {
      qty = 0;
    }
    if (isNaN(parseFloat(taxPer))) {
      taxPer = 1;
    }
    if (isNaN(parseFloat(index.price))) {
      price = 0;
    }
    result = parseFloat(index.price) * parseInt(index.qty)
    if (isNaN(result)) {
      result = 0;
    }  
    return result / taxPer;
  }
    
  const handleDeleteRow = (mainIndex) => {

    let data = [];
    let mainData = [...gridData];
    for (let index = 0; index < mainData.length; index++) {
      const element = mainData[index];
      if (mainIndex != index) {
        data.push({ ...element })
      }

    }
    setGridData([...data])
    rest.handleChange([...data]); 
  }
  const handleAddRow = (index) => {
    debugger
    let data = {...gridData[index]};
    let gdata = [...gridData];
    

    // let keys = Object.keys(_item);
    // keys.map((item, index) => {
    //   _item[item] = null
    // }
    // )
    data.qty=0;
    gdata.push(data);
    setGridData(gdata)
    rest.handleChange(gdata); 
  }
 
  const handleChange = async (event, index, idenifier) => {
    let data = [...gridData]; 
    if (idenifier === "productCode") {
      data[index][idenifier] = event;
      let result = await getproductdata(event);
      data[index].productName = result.name;
      data[index].taxPer = result.taxPer;
      data[index].mrp = result.price;
      data[index].hsn = await gethsnData(result.hsnGroupId); 
    }
    else {
      data[index][idenifier] = event.target.value;
    }
    if(idenifier != "mfBatchNo" && idenifier != "expiryDate")
    {
      let totalAmount = await getlineTotal(data[index])
      let totalTax = await getlineTaxTotal(data[index])
      data[index].totalAmount = totalAmount + totalTax;
      data[index].totalTaxAmt = totalTax ;
    }
    
    setGridData(data)
    rest.handleChange(data);

  } 
  useEffect(() => {
    setGridData([...rest.gridData])
  }, []);
 const loadSummary = () => {

    let _gdata = [];
    let _summary = [];
    _gdata = [...gridData];
    let totalTax = 0;
    let totalAmt = 0;
    let totalQty = 0;
    let totalLines = 0;

    for (let index = 0; index < _gdata.length; index++) {
      const element = _gdata[index];
      if (parseFloat(element.totalTaxAmt)) {
        totalTax = totalTax + parseFloat(element.totalTaxAmt)
      }
      if (parseFloat(element.totalAmount)) {
        totalAmt = totalAmt + parseFloat(element.totalAmount)
      }
      if (parseInt(element.qty)) {
        totalQty = totalQty + parseInt(element.qty)
      }
      if ((element.productCode)) {
        totalLines = totalLines + 1;
      }


    }  
    _summary.push({ "head": "Total Lines", "value": (totalLines) })
    _summary.push({ "head": "Total Qty", "value": (totalQty) })
    if (rest.taxType == 2) {
      _summary.push({ "head": "IGST", "value": totalTax.toFixed(2) }) 
    } 
    if (rest.taxType == 1) {
      _summary.push({ "head": "CGST", "value": (totalTax.toFixed(2) / 2).toFixed(2) })
      _summary.push({ "head": "SGST", "value": (totalTax.toFixed(2) / 2).toFixed(2) })
    }   
    _summary.push({ "head": "Total Tax", "value": (totalTax).toFixed(2) })  
    _summary.push({ "head": "Total Amount", "value": totalAmt.toFixed(2) }) 
    
    return _summary.map((item, index) => { 
      return <div className='summary'>
        <div className='summary-row'>
          <div className='summary-head'>{item.head}
          </div>
          <div className='summary-value'>
            {item.value}
          </div>
        </div> 
      </div> 
    });

  } 

  return (
    <React.Fragment>
      <div className="transaction-detail "> 
      Goods Inward Product Detail
        <div> 
        </div>
      </div>
      <div style={{width:"100%",height:"100%" }}>
      <Table className={classes.customtable} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 5 }} ></TableCell>
            {/* <TableCell style={{ width: 200 }} >Product Code</TableCell> */}
            <TableCell >Product Name</TableCell>
            <TableCell >HSN</TableCell>
            <TableCell style={{ width: 100 }} >Manf Batch</TableCell>
            <TableCell  style={{ width: 100 }}>Expiry Date</TableCell>
            <TableCell >Tax Per</TableCell> 
            <TableCell style={{ width: 100 }}>MRP</TableCell>
            <TableCell style={{ width: 100 }}>Price</TableCell>
            <TableCell style={{ width: 100 }}>Quantity</TableCell>
            <TableCell >Total Tax </TableCell>
            <TableCell style={{ width: 100 }}>Total Amount</TableCell>

          </TableRow>
        </TableHead>
        <TableBody >
          {gridData.map((item, index) => {
            return <tr >
              <TableCell style={{ width: 5 }}> 
                {index > 0 && <span onClick={(event) => { handleDeleteRow(index) }} className='transaction-link-button'  ><DeleteIcon /></span>}
              </TableCell>
              {/* <TableCell>
                <ReferenceInput label=""
                  perPage={5000} source={"productCode" + index}
                  reference="products"
                  defaultValue={item.productCode}
                  disabled={true}
                  onChange={(event) => handleChange(event, index, "productCode")}>
                  <SelectInput disabled={true} optionText="productCode" variant="outlined"  validate={[required()]} />
                </ReferenceInput>
              </TableCell> */}
              <TableCell> 
                {/* <ReferenceInput label=""
                  perPage={5000} source={"productCode" + index}
                  reference="products"
                  disabled={true}
                  defaultValue={item.productCode}
                  onChange={(event) => handleChange(event, index, "productCode")}>
                  <SelectInput disabled={true} optionText="productName" variant="outlined"  validate={[required()]} />
                </ReferenceInput>   */}
                <div>
                  <div>
                  {"PCode :- "}{item.productCode}
                  </div>
                  <div>
                  {"PName :- "} {item.productName}    
                  </div>
                </div>
                
                
                
              </TableCell>
              <TableCell>
                {item.hsn}     {"  "} 
              </TableCell>
              <TableCell>
              <TextInput source="mfBatchNo"  value={item.mfBatchNo} variant="outlined" label="" initialValue={""} fullWidth={true} 
              onChange={(event) => handleChange(event, index, "mfBatchNo")} 
              />
              </TableCell>
              
              <TableCell>
                  <DateInput source={"expiryDate"}  value={item.expiryDate}  label={""} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_4_input}
                  onChange={(event) => handleChange(event, index, "expiryDate")} 
                  initialValue={item.expiryDate}
                  />         
              </TableCell>
              
              <TableCell>
                {item.taxPer}   {"  "} 
              </TableCell>
              <TableCell>
                {item.mrp}   {"  "} 
              </TableCell>
              
              <TableCell>
                <div className='transaction-sm-col'>
                  {item.productCode && item.productCode > 0 ? <NumberInput validate={[required()]} source={"price" + index} label="" variant="outlined"
                    value={item.price}
                    initialValue={item.price}
                    onChange={(event) => handleChange(event, index, "price")} /> : null} 
                </div> 
              </TableCell>
              <TableCell>
                <div className='transaction-sm-col'> 
                  {item.productCode && item.productCode > 0 ?
                    <NumberInput validate={[required()]} source={"qty" + index} label="" variant="outlined" initialValue={item.qty} onChange={(event) => handleChange(event, index, "qty")} />
                    : null}
                </div>
              </TableCell>
              <TableCell>
                {item.totalTaxAmt}     {"  "} 
              </TableCell>
              <TableCell>
                <div className='transaction-sm-col'>
                  {item.totalAmount}
                  { <Button onClick={(event)=>handleAddRow(index)} ><CallSplit /></Button>}
                </div>
              </TableCell>
            </tr>
          })}
        </TableBody>
      </Table>
      </div>
      <div>
        {loadSummary()}
      </div>
    </React.Fragment>


  )
}
