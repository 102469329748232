import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField,
  NumberInput
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";
import CompanyDocuments from "./CompanyDocuments";
import CompanyCostCenterCreate from './component/CompanyCostCenterCreate';
import CompanyCostCenterEdit from './component/CompanyCostCenterEdit';
import BranchEdit from './component/BranchEdit';
import BranchCreate from './component/BranchCreate';
import FacilityCreate from './component/FacilityCreate';
import FacilityEdit from './component/FacilityEdit';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 650,
  },
}));

const DisplayImage = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <div style={{width:"50px",height:"50px"}}> 
        <img                    src={record[source]}
                      width="100%"
                      height="100%"
                      
        /> </div>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: 100,
  },
}));

export const TPAEditButton = props => {
  const setId = () => {
    props.setRecordEdit(props.record.id);
  }
  return (
    <Button
      color="secondary" disableElevation
      size="medium"

      onClick={setId}
      startIcon={<EditIcon />}>Edit</Button>
  )
};

export const ConfigsEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  const [isaddTpa, setIsAddTpa] = useState(false);
  const [isEditTpa, setIsEditTpa] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const [isAddCostCenter, setIsAddCostCenter] = useState(false);
  const [isEditCostCenter, setIsEditCostCenter] = useState(false);
  const [isAddBranch, setIsAddBranch] = useState(false);
  const [isEditBranch, setIsEditBranch] = useState(false);
  const [isAddFacility, setIsAddFacility] = useState(false);
  const [isEditFacility, setIsEditFacility] = useState(false);
  const myclasses = myStyles({});
  const refresh = useRefresh();

  const closeEdit = () => {

    setIsAddDocuments(false);
    setIsAddCostCenter(false);
    setIsEditCostCenter(false);
    setIsAddBranch(false);
    setIsEditBranch(false);
    setIsAddFacility(false);
    setIsEditFacility(false);

    refresh();
  }


  const onCancel = () => {
    setIsAddTpa(false);
    setIsEditTpa(false);
    setIsAddDocuments(false);
    setIsAddCostCenter(false);
    setIsEditCostCenter(false);
    setIsAddBranch(false);
    setIsEditBranch(false);
    setIsAddFacility(false);
    setIsEditFacility(false);
  }

  const handleClose = useCallback(() => {
    setIsAddTpa(false);
    //setIsAccountServiceAdd(false);
  }, []);

  const EditRecord = (props) => {
    let { record } = props;
    return (
      <div className="upload-document" onClick={() => {
        props.onClick(record);
      }}>Edit </div>
    )
  }

  return (
    <React.Fragment>
      <Edit title="Company Edit" {...propsObj}>

        <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...propsObj} />}>
          <FormTab value="DETAILS" label="Company Detail">
            <ReferenceInput label="Company Group"
              fullWidth={true}
              source="companyGroupId"
              reference="company-groups" >
              <SelectInput optionText="name" variant="outlined" fullWidth={true} />
            </ReferenceInput>
            <TextInput validate={[required()]} source="mconame" label="Company Name" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput validate={[required()]} source="comphd" label="Company Heading " fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput label="Address 1" source="add1" fullWidth={true} />
            <TextInput label="Address 2" source="add2" fullWidth={true} />
            <TextInput label="Address 3" source="add3" fullWidth={true} />
            <TextInput source="phone" label="Phone" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput source="fax" label="Fax" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput label="Other Address 1" source="delvadd1" fullWidth={true} />
            <TextInput label="Other Address 2" source="delvadd2" fullWidth={true} />
            <TextInput label="Other Address 3" source="delvadd3" fullWidth={true} />
            <TextInput source="delvPhone" label="Other Phone" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput source="delvfax" label="Other Fax" fullWidth={true} formClassName={classes.last_inline_input} />

            <DateInput label="From Date" source="fromDate" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="To Date" source="toDate" fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput label="Closing Stock" source="closstock" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput label="Cur Date" source="curDate" fullWidth={true} formClassName={classes.last_4_input} />

            <TextInput label="CIN NO" source="lstno" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput label="PAN" source="tin" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput label="GST No" source="cstno" fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="Store Near Range Config(Km)" source="storeNearByRange" fullWidth={true} formClassName={classes.last_4_input} />

            <BooleanInput label="Is Active" source="isactive" fullWidth={true} formClassName={classes.one_4_input} />



          </FormTab>

          {/* <FormTab value="DETAILS" label="Realization Order Term">
        
          <TextInput source="salterms1" label=""  fullWidth={true}/>
          <TextInput source="salterms2" label=""  fullWidth={true}/>
          <TextInput source="salterms3" label=""  fullWidth={true}/>
          <TextInput source="salterms4" label=""  fullWidth={true}/>
          <TextInput source="salterms5" label=""  fullWidth={true}/>
          <TextInput source="salterms6" label=""  fullWidth={true}/>
          </FormTab> */}
          <FormTab label="Cost Centers">
            <Button variant="contained" onClick={() => { setIsAddCostCenter(true); }}
              color="secondary">
              Add Cost Center
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="company-cost-centers"
              perPage={500}
              target="companyId">
              <Datagrid>
                <VSDeleteButton {...props} />

                <EditRecord onClick={(record) => { setRecordId(record); setIsEditCostCenter(true); }} label="Edit" />
                <TextField label="Cost Center " source="name" />
                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="taxTypeId" reference="tax-heads" label="Tax Type" link={false}>
                  <TextField source="taxName" />
                </ReferenceField>
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />


              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab label="Warehouse">
            <Button variant="contained" onClick={() => { setIsAddBranch(true); }}
              color="secondary">
              Add Warehouse
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="company-branches"
              perPage={500}
              target="companyId">
              <Datagrid>
                <VSDeleteButton {...props} />

                <EditRecord onClick={(record) => { setRecordId(record); setIsEditBranch(true); }} label="Edit" />
                <TextField label="Warehouse " source="name" />
                <ReferenceField source="compCostCenterId" reference="company-cost-centers" label="Cost Center" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />


              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Facilities">
            <Button variant="contained" onClick={() => { setIsAddFacility(true); }}
              color="secondary">
              Add Facilitiy
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="company-facilities"
              perPage={500}
              target="companyId">
              <Datagrid>
                <VSDeleteButton {...props} />

                <EditRecord onClick={(record) => { setRecordId(record); setIsEditFacility(true); }} label="Edit" />
                <TextField label="Facilitiy " source="name" />
                <ReferenceField source="compCostCenterId" reference="company-cost-centers" label="Cost Center" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="compBranchId" reference="company-branches" label="Branch" link={false}>
                  <TextField source="name" />
                </ReferenceField> 
                
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />


              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Documents">
            <Button variant="contained" onClick={() => { setIsAddDocuments(true); }}
              color="secondary">
              Add Document
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="configdets"
              perPage={500}
              target="configId">
              <Datagrid>
                <VSDeleteButton {...props} />
                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                <TextField label="Document Type" source="docType" />
                <TextField label="Document Value" source="docValue" />
                <DisplayImage label="Document" source="url"/>
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>


      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isaddDocuments || isAddCostCenter || isEditCostCenter || isAddBranch || isEditBranch || isAddFacility || isEditFacility} >
        {isaddDocuments && <CompanyDocuments
          onClose={closeEdit}
          onCancel={onCancel}
          configId={props.id}
          {...props} />}
        {isAddCostCenter && <CompanyCostCenterCreate
          onClose={closeEdit}
          onCancel={onCancel}
          configId={props.id}
          {...props} />}
        {isEditCostCenter && <CompanyCostCenterEdit
          onClose={closeEdit}
          onCancel={onCancel}
          configId={props.id}
          record={recordId}
          {...props} />}

        {isAddBranch && <BranchCreate
          onClose={closeEdit}
          onCancel={onCancel}
          configId={props.id}
          {...props} />}
        {isEditBranch && <BranchEdit
          onClose={closeEdit}
          onCancel={onCancel}
          configId={props.id}
          record={recordId}
          {...props} />}


        {isAddFacility && <FacilityCreate
          onClose={closeEdit}
          onCancel={onCancel}
          configId={props.id}
          {...props} />}
        {isEditFacility && <FacilityEdit
          onClose={closeEdit}
          onCancel={onCancel}
          configId={props.id}
          record={recordId}
          {...props} />}

      </Drawer>

    </React.Fragment>
  );
};
