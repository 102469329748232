import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

 const Addressfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div >
             <ReferenceInput variant="outlined" label="Country" source="countryId" reference="countries"
                onChange={(v) => {                    
                    form.change("stateId", 0);
                }}
                sort={{ field: 'name', order: 'ASC' }}
                validate={required()}
                fullWidth={true} perPage={500} className={classes.one_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="State" source="stateId"
                reference="states" perPage={500}
                filter={{ "countryId": formData.countryId }}
                validate={required()}
                onChange={(v) => {
                    form.change("cityId", 0);
                }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} className={classes.two_three_input}>
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>
            <ReferenceInput variant="outlined" label="City" source="cityId"
                reference="cities" perPage={500}
                filter={{ "stateId": formData.stateId }}
                validate={required()}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} className={classes.last_three_input}>
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>  
            {/*
            <ReferenceInput variant="outlined" label="District" source="districtId"
                reference="districts" perPage={500}
                filter={{ "stateId": formData.stateId }}
                validate={required()}
                onChange={(v) => {
                    form.change("cityId", 0);
                }}
                fullWidth={true} className={classes.three_four_input}>
                <SelectInput validate={required()} optionText="districtName" />
            </ReferenceInput>

            
            <ReferenceInput variant="outlined" label="City" source="cityId"
                reference="cities" perPage={500}
                filter={{ "districtId": formData.districtId }}
                validate={required()}
                fullWidth={true} className={classes.four_four_input}>
                <SelectInput validate={required()} optionText="cityName" />
            </ReferenceInput> */}
        </div>
    )
}
export default Addressfields;
