import {AdvisoryPanelList} from "./AdvisoryPanelList";
import {AdvisoryPanelEdit} from "./AdvisoryPanelEdit";
import {AdvisoryPanelCreate} from "./AdvisoryPanelCreate";


export default {
    list: AdvisoryPanelList,
    create: AdvisoryPanelCreate,
    edit: AdvisoryPanelEdit,
};
