import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Order Number" source="orderNo~like" alwaysOn />
    </Filter>
);
export const ActivityList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      // propsObj.hasCreate =(p.Create == 1) ? true : false;
      propsObj.hasCreate=false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    //<List {...props} title="UOM List" bulkActionButtons={false} filters={<Filters />}  >
      <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}
       title="Dispatch Order List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="orderNo" label="Order No" />
        <DateField  source="orderDate" label="Order Date" locales="fr-FR"/>
        <ReferenceField source="franchiseId" reference="accounts" label="Franchise"  link={false}>
                <TextField source="accountName" />
            </ReferenceField>
        <ReferenceField source="statusId" reference="lookups" label="Status"  link={false}>
                <TextField source="name" />
            </ReferenceField>
        
            <DateField  source="CreatedDate" label="Created Date" locales="fr-FR"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date" locales="fr-FR"/>
      </Datagrid>
    </List>
  );
};
