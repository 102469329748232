import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  FileInput, FileField, 
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const ManufactureCreate = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  const initialValues = {
    
    code:"-",
    url:"",
    urlHindi:"",
}
  return (
    <Create   {...propsObj} title="Manufacture Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={initialValues}>
      
       <TextInput source="name" validate={[required()]} label="English Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="nameHindi" validate={[required()]} label="Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       
       <ImageField source="url" title="English Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="English "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>

         <ImageField source="urlHindi" title="Hindi Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="hindiUpurl" 
              formClassName={classes.last_inline_input}
              label="Hindi "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="hindiUpurl" title="Icon" />
            </ImageInput>
      
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Create>
  );
};
