import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

export const ProductCategoryCreate = (props) => {
  const classes = useStyles({});
  const initialValues = {
    
    url:"",
    urlHindi:"",
}
  return (
    <Create title="Product Category Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={initialValues} >
      <ReferenceInput  perPage={2000} label="Product Type" source="productTypeId" reference="product-types" 
      fullWidth={true} sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" validate={required()}  disabled={false}/>
                </ReferenceInput> 
       <TextInput source="cateName" validate={[required()]} label="English Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="cateNameHindi" validate={[required()]} label="Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       
       <ImageField source="url" title="English Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="English "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>

         <ImageField source="urlHindi" title="Hindi Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="hindiUpurl" 
              formClassName={classes.last_inline_input}
              label="Hindi "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="hindiUpurl" title="Icon" />
            </ImageInput>
       
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />

      </SimpleForm>
    </Create>
  );
};
