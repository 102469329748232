import React,{useState,useCallback} from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  NumberInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceField,
  useRefresh,
  ImageField,
  Create,
  ImageInput,
  FormDataConsumer

} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { useStyles } from "../../formStyles";
import CustomSelectInput from "./CustomSelectInput";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));



export const ProductDiseaseCreate = (props) => {
  const myclasses = myStyles({});
  const classes = useStyles({});
  const [isadd, setIsAdd] = useState(false);
  const refresh = useRefresh();
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const SectionHeading = (props) => {
    const { caption } = props;
    return (
      <div className="heading">
        <h3>{caption}</h3>
      </div>
    )

  } 
  const onCancel = () => {
   setIsAdd(false);
  }
  const handleClose = useCallback(() => {
    onCancel();
  }, []);
  const onCloseDocumentForm = () => {
    setIsAdd(false);
   
    refresh();
  }
  const SecondaryButton = (props) => {
    const { caption, onClick } = props
    return (
      <div style={{ flex: 1 }}>
        <Button variant="contained" color="secondary" style={{ marginRight: "10" }} onClick={onClick}>{caption}</Button>
      </div>
    )
  } 
  const DisplayFileLink = (props) => {
    let { record, source } = props;
    if (source && record[source] && record[source].length > 0)
      return (
        <a href={record[source]}>View File</a>
      )
    else {
      return null
    }
  }
  return (
    <React.Fragment>
    <Create {...propsObj}  title="Product Disease Edit">
      {/* <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined"> */}
      <TabbedForm
          
          redirect="list" variant="outlined" toolbar={<FormToolbar {...propsObj} showDelete={false} />}>
      <FormTab label="Basic Details">
      <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="product-groups" selectedText={""} source={"productGroupId"}  optionText={"groupName"} label={"Product Group"} fullWidth={true} {...formDataProps} 
                
                dependentFields={["productId"]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="products"   selectedText={""} source={"productId"}  optionText={"productName"} label={"Product"} fullWidth={true} {...formDataProps} 
                filters={[{"productGroupId":formDataProps.productGroupId}]}
                />
              )}
            </FormDataConsumer>

      <ReferenceInput  perPage={2000} label="Stage" source="stageId" reference="stages" 
      fullWidth={true} sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" validate={required()}  disabled={false}/>
      </ReferenceInput> 
      
      
      <TextInput source="diseaseName" validate={[required()]} label="Disease Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="diseaseNameHindi" validate={[required()]} label="Disease Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       <TextInput multiline source="moreDetails" label="English Desciption" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput multiline source="moreDetailsHindi"  label="Hindi Desciption" fullWidth={true}  formClassName={classes.last_inline_input}/> 
       <ImageField source="url" title="English Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="English "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>

         <ImageField source="urlHindi" title="Hindi Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="hindiUpurl" 
              formClassName={classes.last_inline_input}
              label="Hindi "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="hindiUpurl" title="Icon" />
            </ImageInput>
      
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
        </FormTab>
         
      </TabbedForm>
    </Create>
    

       
    </React.Fragment>
  );
};
