import {SupplierList} from "./SupplierList";
import {SupplierEdit} from "./SupplierEdit";
import {SupplierCreate} from "./SupplierCreate";


export default {
    list: SupplierList,
    create: SupplierCreate,
    edit: SupplierEdit,
};
