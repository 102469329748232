import React,{useState,useEffect} from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  DateInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import * as apiClient from "../../common/apiClient";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import {
  useHistory
} from "react-router-dom";
export const ActivityCreate = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  let location = useLocation();
  let history = useHistory();
  
  const [defaultValues, setdefaultValues] = useState<any>({});

  
const getOrder = (oid: any) => {
  
  apiClient.getEntiyRecord("sale-orders", oid).then(res => {
    //if(res) {
    
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    } 
    setdefaultValues({
      "orderNo": "",
      "orderDate": new Date(),
      "franchiseId": res.franchiseId,
      "branchId": res.branchId,
      "saleOrderId": res.id,
      "quotationOrder": res.quotationOrder,
      "createdBy": userId,
      "createdDate": new Date(), 
      "statusId": 146
    });
    
  
    setIsLoading(false);
    //   }
  }).catch(err => {
    console.log(err);
  });
}
const handleSubmit = async (formdata) => { 
  
  apiClient.addEntiyRecord("dispatch-orders",formdata).then(res => { 
      history.push(`/dispatch-orders/${res.id}`);
    
  }).catch(err => {
    console.log(err);
  });
}

useEffect(() => {
  const values = queryString.parse(location.search);
  let oid = (values.oid) ? values.oid : '';
  if (oid != "") {
    getOrder(oid);
  } else {
    setIsLoading(false);
  }

}, []);
  return (
    <React.Fragment>
    {!isLoading && <Create title="Add Dispatch Order" {...props} >
      
        <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" initialValues={defaultValues} >
        <TextInput source="orderNo" fullWidth={true} disabled  /> 
        <DateInput source={"orderDate"} label={"Dispatch Order Date"} disabled fullWidth={true} validate={[required()]} variant="outlined"  />         
        
        <ReferenceInput source="saleOrderId" reference="sale-orders" label="Sale Order No"  fullWidth={true}    disabled link={false}>
                <SelectInput optionText="orderNo" />
            </ReferenceInput>
                  
        <ReferenceInput source="quotationOrder" reference="quotation-orders" label="Quotation Order No"  fullWidth={true}    disabled link={false}>
                <SelectInput optionText="orderNo" />
            </ReferenceInput>
        <ReferenceInput source="franchiseId" reference="accounts" label="Franchise"  fullWidth={true}    disabled link={false}>
                <SelectInput optionText="accountName" />
            </ReferenceInput>
      </SimpleForm>
    </Create>
  }
  </React.Fragment>
  );
};
