import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField,
  ImageInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const ProductGroupCreate = (props) => {
  const initialValues = {
    
    groupCode:"",
    url:"",
    urlHindi:"",
    isBannerActive:false,
    bannerImage:""
  }
  const classes = useStyles({});
  return (
    <Create title="Product Group  Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={initialValues}> 
       <ReferenceInput  perPage={2000} label="Product Category" source="productCategoryId" reference="product-categories" 
      fullWidth={true} sort={{ field: 'cateName', order: 'ASC' }}>
                    <SelectInput optionText="cateName" validate={required()}  disabled={false}/>
                </ReferenceInput> 
       <TextInput source="groupName" validate={[required()]} label="English Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="groupNameHindi" validate={[required()]} label="Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       
       <ImageField source="url" title="English Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="English "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>

         <ImageField source="urlHindi" title="Hindi Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="hindiUpurl" 
              formClassName={classes.last_inline_input}
              label="Hindi "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="hindiUpurl" title="Icon" />
            </ImageInput>
            <ImageField source="bannerImage" title="Banner Image" formClassName={classes.first_inline_input} />
          <ImageInput
              source="bannerImageUpurl" 
              formClassName={classes.last_inline_input}
              label="Banner Image "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="bannerImageUpurl" title="Icon" />
            </ImageInput>
        <BooleanInput source="isBannerActive"  label="Banner Active"  />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Create>
  );
};
