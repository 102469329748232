import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
      <SearchInput variant="outlined" label="Discount Code" source="discountCode~like" alwaysOn />
        <SearchInput variant="outlined" label="Discount Name" source="discountName~like" alwaysOn />
    </Filter>
);
export const DiscountList = (props) => {
  return (
    <List {...props} title="Discount List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="discountCode" label="Discount Code" />
        <TextField source="discountName" label="Discount Name" />
        <NumberField  source="discountpc" label="Discount %" />
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DateField  source="createdDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
        <DateField  source="modifyDate" label="Updated Date"/>
        
        
      </Datagrid>
    </List>
  );
};
