import React, { useState,useEffect } from "react";
import * as apiClient from "../../common/apiClient"; 
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

import { GridDetail } from './GridDetail';

export const GoodsInwardOrderCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect=useRedirect();
  //const [gridData, setGridData] = useState([{ lineNumber: 1, productCode:null , productName: null, taxPer:0,totalTaxAmt:null, hsn:"", uomId: 0,mrp:null, price: null, qty: null, totalAmount: null}]);
  const [gridData, setGridData] = useState([]);
  const [taxType, setTaxType] = useState(0);

  useEffect(() => {
  setGridData(gridData) 
  });
  
  const handleChange = (indata) => { 
     setGridData(indata);
  }

  const loadTaxType = async (e) => {
    
    await apiClient.getSuppilerdata(e).then(res => { 
      setTaxType(res.gstType);  
    }).catch(err => {
      console.log(err);
    });
    
  }

const loadData =  async(event) => {
    await apiClient.getPendingPurchaseOrder(event.target.value).then(res => {
      debugger
      let data=JSON.parse(res[0].data);
      setGridData(data[0].lineItems)
      setTaxType(data[0].taxType)
      
    }).catch(err => {
      console.log(err);
       
    });
    
}
const handleSubmit = async (formdata) => {
        //alert("c");
        debugger
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }

    let data:any={};
    data.id=0;   
    data.branchId=formdata.branchId;
    data.vendorId=formdata.vendorId;
    data.documentNo=formdata.documentNo;
    data.goodsInwardDate=formdata.goodsInwardDate;
    data.taxType=taxType; 
    data.remarks=formdata.remarks;  
    data.purchaseOrderId =formdata.purchaseOrderId ; 
    
    data.lineItems=[...gridData];
    // let m = moment(new Date());
    
    // data. modifyDate=  m.toISOString()
     data.createdBy=  userId
    apiClient.addeditGoodsInwardOrder( data).then(res => {
      if(res[0].id>0)
      {
        notify(res[0].remarks)
        redirect("/goods-inward-orders");
      }
      else
      {
        notify(res[0].remarks,"warning")

      }
        //console.log(res);
        //props.onClose();
    }).catch(err => {
        console.log(err);
    });
}
  return (
    <Create title="Goods Inward Order Create" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} 
      toolbar={<FormToolbar  onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >
        <div className="transaction-header">
          Goods Inward Order Detail
        </div>
        <DateInput source={"goodsInwardDate"} label={"Goods Inward Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_4_input}  initialValue={new Date()}/>         
        <ReferenceInput label="Supplier" fullWidth={true} formClassName={classes.two_4_input}
          perPage={5000} source="vendorId"
          onChange={(e)=>loadTaxType(e)}
          reference="suppliers" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="supplierName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>  
        <ReferenceInput label="Warehouse" fullWidth={true} formClassName={classes.three_4_input}
          perPage={5000} source="branchId"
          filter={{ userId:localStorage.getItem("userId") }}
          reference="company-branches" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>  
        <ReferenceInput label="Purchase Order" fullWidth={true} formClassName={classes.last_4_input}
          perPage={5000} source="purchaseOrderId"
          onChange={(e)=>loadData(e)}
          reference="purchase-orders" >
          <SelectInput optionText="purchaseNo" variant="outlined"  validate={[required()]} />
          {/* <AutocompleteInput optionText="purchaseNo" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} /> */}
        </ReferenceInput>  
        
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.one_4_input} />
       
        <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true}  />
        {gridData.length>0 && <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail {...formDataProps} gridData={gridData} taxType={taxType}  handleChange ={handleChange}/>
              )}
            </FormDataConsumer>
    }
            
              </SimpleForm>
    </Create>
  );
};
