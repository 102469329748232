import React,{useState,useCallback} from "react";
import { DisplayDate } from '../../common/DisplayDate';
import {
  Edit,
  TabbedForm,
  TextInput,
  NumberInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceField,
  useRefresh,
  ImageField,
  ImageInput

} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import DocumentEdit from './docs/DocumentEdit';
import DetailCreate from './docs/DetailCreate';
import { useStyles } from "../../formStyles";
const useImageFieldStyles = makeStyles(theme => ({
  image: { // This will override the style of the <img> inside the <div>
      width: 200,
      height: 200,
  }
}))
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));



export const UserEnqEdit = (props) => {
  const myclasses = myStyles({});
  const classes = useStyles({});
  const imageFieldClasses = useImageFieldStyles();
  const [isadd, setIsAdd] = useState(false);
  const refresh = useRefresh();
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const CustomDelete = (props) => {
    let { record, reply } = props;
    
    // if  //(reply && record[reply] && record[reply].length > 0)
    if(record.reply)
      return (
        <CDeleteButton {...props} cellClassName="delete-column" />
      )
    else {
      return null
    }
  }
  const SectionHeading = (props) => {
    const { caption } = props;
    return (
      <div className="heading">
        <h3>{caption}</h3>
      </div>
    )

  } 
  const onCancel = () => {
   setIsAdd(false);
  }
  const handleClose = useCallback(() => {
    onCancel();
  }, []);
  const onCloseDocumentForm = () => {
    setIsAdd(false);
   
    refresh();
  }
  const SecondaryButton = (props) => {
    const { caption, onClick } = props
    return (
      <div style={{ flex: 1 }}>
        <Button variant="contained" color="secondary" style={{ marginRight: "10" }} onClick={onClick}>{caption}</Button>
      </div>
    )
  } 
  const DisplayFileLink = (props) => {
    let { record, source } = props;
    if (source && record[source] && record[source].length > 0)
      return (
        <a href={record[source]}>View File</a>
      )
    else {
      return null
    }
  }
  return (
    <React.Fragment>
    <Edit {...propsObj}  title="User Enquiry Edit">
      {/* <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined"> */}
      <TabbedForm
          
          redirect="list" variant="outlined" toolbar={<FormToolbar {...propsObj} showDelete={false} />}>
      <FormTab label="Enquiry Details">
      <ReferenceInput  perPage={2000} label="Product Group" source="productGroupId" reference="product-groups" 
      fullWidth={true} sort={{ field: 'groupName', order: 'ASC' }}>
                    <SelectInput optionText="groupName" validate={required()}  disabled={true}/>
                </ReferenceInput> 
       <TextInput multiline source="description" label="Description" fullWidth={true}  disabled={true}/>
       
       
       
       <ImageField source="url" label="Image 1"  fullWidth={true} />
       <ImageField source="url1" label="Image 2"  fullWidth={true} />  
        <BooleanInput source="isActive" disabled={true} label="Active" />
        </FormTab>
        <FormTab label="Enquiry Reply"> 
            <SectionHeading caption="" />
            <SecondaryButton formClassName="secondary-action-btn" caption="Add Reply" onClick={() => setIsAdd(true)} />
            <ReferenceManyField fullWidth={true}
              addLabel={false} 
              sort={{ field: 'id', order: 'ASC' }} 
              reference="user-product-group-enq-dtls"
              target="enqId">
              <Datagrid >
                {/* <CustomDelete {...props}/> */}
                
                
                <TextField label="Ask" source="ask" />
                <TextField label="Reply" source="reply" />   
                <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="firstName" />
            </ReferenceField>
              <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
              </Datagrid>
            </ReferenceManyField>            
          </FormTab>
      </TabbedForm>
    </Edit>
    

      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isadd} 
       >
 {isadd  && <DetailCreate
          onClose={() => { setIsAdd(false); refresh(); }}
          onCancel={onCancel}
          enqId={props.id}
          record={props.id}
          {...props} />
 }
        
      </Drawer>
    </React.Fragment>
  );
};
