import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const HSNGroupCreate = (props) => {
  return (
    <Create title="HSN Group Create" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <TextInput source="nameHindi" fullWidth={true} validate={[required()]} />
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
      </SimpleForm>
    </Create>
  );
};
