import React,{useState,useCallback} from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  NumberInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceField,
  useRefresh,
  ImageField,
  FormDataConsumer

} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import DocumentEdit from './docs/DocumentEdit';
import DocumentsCreate from './docs/DocumentsCreate';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));



export const ContentPageEdit = (props) => {
  const myclasses = myStyles({});
  const [isadd, setIsAdd] = useState(false);
  const [isCanadd, setIsCanAdd] = useState(false);
  const [sectionType, setSectionType] = useState(0);
  const refresh = useRefresh();
  let propsObj = { ...props };
  
  if (props.id===7)
  {
    
    setIsCanAdd(true);
    
  }
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const SectionHeading = (props) => {
    const { caption } = props;
    return (
      <div className="heading">
        <h3>{caption}</h3>
      </div>
    )

  } 
  const onCancel = () => {
   setIsAdd(false);
  }
  const handleClose = useCallback(() => {
    onCancel();
  }, []);
  const onCloseDocumentForm = () => {
    setIsAdd(false);
   
    refresh();
  }
  const SecondaryButton = (props) => {
    const { caption, onClick } = props
    return (
      <div style={{ flex: 1 }}>
        <Button variant="contained" color="secondary" style={{ marginRight: "10" }} onClick={onClick}>{caption}</Button>
      </div>
    )
  } 
  const DisplayFileLink = (props) => {
    let { record, source } = props;
    if (source && record[source] && record[source].length > 0)
      return (
        <a href={record[source]}>View File</a>
      )
    else {
      return null
    }
  }

  const VideoFrame = (props) => {
    let { record, source } = props;
    if (source && record[source] && record[source].length > 0)
      return (
        <a href={record[source]} target="_blank" >View Video</a>
        
//  <video 
//                         src={record[source]}
//                         width="200"
//                         height="200"
//                         controls
//           />

      )
    else {
      return null
    }
  }
  return (
    <React.Fragment>
    <Edit {...propsObj}  title="Content Page Edit">
      {/* <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined"> */}
      <TabbedForm
          
          redirect="list" variant="outlined" toolbar={<FormToolbar {...propsObj} showDelete={false} />}>
      <FormTab label="Basic Details">
      <ReferenceInput  perPage={2000} label="Section Type" source="sectionType" reference="lookups" 
      fullWidth={true} sort={{ field: 'name', order: 'ASC' }} filter={{ type: 'FST' }} >
                    <SelectInput optionText="name" validate={required()}  disabled={true}/>
                </ReferenceInput> 
      
      <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.sectionType &&
            formData.sectionType != 113 &&
            formData.sectionType != 106 && (
              <TextInput source="sectionName" variant="outlined"  fullWidth={true}  validate={[required()]}/>
            )
          }
        </FormDataConsumer>
        
      <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.sectionType &&
            (formData.sectionType == 113 ||
            formData.sectionType == 106) && (
              <TextInput source="sectionName" variant="outlined"  fullWidth={true} />
            )
          }
        </FormDataConsumer>
        <TextInput source="sectionNameHindi" fullWidth={true}  />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
        </FormTab>
       
        {(sectionType == 106 ||sectionType == 108 ||sectionType == 113) &&  <FormTab label="Page Detail"> 
            <SectionHeading caption="Page Detail" />
            <SecondaryButton formClassName="secondary-action-btn" caption="Add Page Detail" onClick={() => setIsAdd(true)} />
            <ReferenceManyField fullWidth={true}
              addLabel={false} 
              reference="content-page-config-dets"
              target="cpId">
              <Datagrid >
                {<CDeleteButton {...props} cellClassName="delete-column" />}                   
                <TextField label="Property Name" source="propertyName" />
                <TextField label="Property Name Hindi" source="propertyNameHindi" />
                <TextField label="Active" source="isActive" />
                <TextField label="Linked" source="isLinked" />
                {sectionType != 113 && <ImageField label="" source="imageUrl" />}
                {sectionType == 113 && <VideoFrame label="" source="imageUrl" />}
                
                
              </Datagrid>
            </ReferenceManyField>            
          </FormTab>}
          <FormDataConsumer >
                            {({ formData, ...rest }) =>{
                              
                              setSectionType(formData.sectionType )
                               
                              }
                          }
          </FormDataConsumer> 
      </TabbedForm>
      
      
    </Edit> 
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isadd} 
       >
 {isadd  && <DocumentsCreate
          onClose={() => { setIsAdd(false); refresh(); }}
          onCancel={onCancel}
          id={props.id}
          sectionType={sectionType}
          record={props.id}
          {...props} />
 }
        
      </Drawer>
    </React.Fragment>
  );
};
