import React,{useState,useEffect} from "react";
import * as apiClient from "../../common/apiClient";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  DateInput,ReferenceField,TextField,FormDataConsumer,useNotify,useRedirect
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { GridDetail } from './GridDetail';
import { useStyles } from "../../formStyles";
export const ActivityEdit = (props) => {
  const [salegridData, setSaleGridData] = useState([]);
  const[isLoading,SetIsLoading]=useState(true)
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles({});
  const handleChange = (indata) => { 
    setSaleGridData(indata);
 }
 
useEffect(() => { 
  loadData()
}, []);


const loadData =  async() => {
  debugger
  SetIsLoading(true)
  await apiClient.getdata_v1(`/dispatch-orders?filter[offset]=0&filter[limit]=1000&filter[order]=Id%20ASC&filter[where][Id]=${props.id}`)
    .then(res => { 
               apiClient.getdata_v1(`/sale-order-dtls?filter[offset]=0&filter[limit]=1000&filter[order]=Id%20ASC&filter[where][orderId]=${res[0].saleOrderId}`)
                      .then(saleres => {   
                        setSaleGridData(saleres); 
                            SetIsLoading(false)
                        }).catch(err => {
                          console.log(err);
                        SetIsLoading(false)
    
  }).catch(err => {
    console.log(err);
    SetIsLoading(false)
  });
}
    )}
  

  const handleSubmit = async (formdata) => {
    
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let formBody={
      userId:userId,
      orderId:props.id,
      data:[...salegridData]
    }
    console.log(formBody);
    apiClient.edtiSaleOrder(formBody).then(res => {
      if (res[0].result) {
        notify(res[0].message)
        redirect("/sale-orders");
      }
      else {
        notify(res[0].message, "warning")

      }
      //console.log(res);
      //props.onClose();
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <Edit title="Dispatch Edit" {...props}>
      <SimpleForm  onSubmit={handleSubmit}  toolbar={<FormToolbar onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >  
        <TextInput source="orderNo" fullWidth={true} disabled formClassName={classes.one_4_input} /> 
        <DateInput source={"orderDate"} label={"Dispatch Order Date"} disabled fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.two_4_input}  />         
        
        <ReferenceInput source="saleOrderId" reference="sale-orders" label="Sale Order No"  fullWidth={true}    disabled link={false} formClassName={classes.three_4_input}>
                <SelectInput optionText="orderNo" />
            </ReferenceInput>
                  
        <ReferenceInput source="quotationOrder" reference="quotation-orders" label="Quotation Order No"  fullWidth={true}    disabled link={false} formClassName={classes.last_4_input}>
                <SelectInput optionText="orderNo" />
            </ReferenceInput>
        <ReferenceInput source="franchiseId" reference="accounts" label="Franchise"  fullWidth={true}    disabled link={false} formClassName={classes.one_4_input}>
                <SelectInput optionText="accountName" />
            </ReferenceInput>
            <div></div>
            <TextInput source="scanBarcode" fullWidth={true} placeholder="Scan Barcode" /> 
            <div style={{display:"flex",width:"100%"}} >
              <div style={{minWidth:"40%"}} >
                    
                    {!isLoading && salegridData.length>0 && <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail title={"Sale Order"} {...formDataProps} taxType={0} gridData={salegridData}  />
              )}
            </FormDataConsumer>}
              </div>
              <div style={{minWidth:"5%"}} >

              </div>
              <div style={{minWidth:"55%"}} >
                 
              {!isLoading && salegridData.length>0 && <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail title={"Scanned Products"} gridType={"dispatch"} {...formDataProps} taxType={0} gridData={salegridData}  />
              )}
            </FormDataConsumer>}
              </div>
            </div>
            {/* {!isLoading && gridData.length>0 && <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail {...formDataProps} taxType={0} gridData={gridData}  handleChange ={handleChange}/>
              )}
            </FormDataConsumer>} */}
        </SimpleForm>
      
    </Edit>
  );
};
