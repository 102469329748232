import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import CreateToolbar from "../../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";
import Facilityfields from './Facilityfields';
import moment from 'moment';
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const FacilityEdit = props => {

    const classes = useStyles({});
    let m = moment(new Date());
    const handleSubmit = async (formdata) => {
         console.log(formdata);

          
         let userid = localStorage.getItem("userId"); 
         let data = props.record;
         
         data.name=formdata.name;
         data.nameHindi=formdata.name;
         data.companyId=parseInt(props.configId);
         data.compCostCenterId= parseInt(formdata.compCostCenterId);
         data.compBranchId= parseInt(formdata.compBranchId);
         data.address1=formdata.address1;
         data.address2=formdata.address2;
         data.zipCode=formdata.zipCode;
         data.isActive=formdata.isActive;    
        data.modifyBy = parseInt(userid);
        data.modifyDate = m.toISOString();
        
        apiClient.updateEntiyRecord("company-facilities", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Edit { ...props } id={ props.record.id } resource="company-facilities" redirect={ false }>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined"> 
               <TextInput validate={[required()]} source="name" label="Facility Name" fullWidth={true} /> 
                <FormDataConsumer>
                    {(formDataProps) => <Facilityfields {...formDataProps} configId={parseInt(props.configId)} />}
                </FormDataConsumer>
                <TextInput   source="address1" label="Address 1" fullWidth={true}  initialValue={""}/>
            <TextInput   source="address2" label="Address 2" fullWidth={true}   initialValue={""}/>
            <TextInput   source="zipCode" label="Pincode" fullWidth={true}   initialValue={""}/>
            
            <BooleanInput label="Is Active" source="isActive" fullWidth={true} />
            </SimpleForm>
        </Edit>
    );
}
export default FacilityEdit;