import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField,
  ImageField,
  Create,
  FormDataConsumer,
  ImageInput
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";
import CompanyDocuments from "./CompanyDocuments";
import Addressfields from "./Addressfields";

const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 650,
  },
}));


export const userStyles = makeStyles(theme => ({
  main: {
      display: "flex",
      justifyContent: "space-between",
  },
  item: {
      width: 100,
  },
}));

export const TPAEditButton = props => {
  const setHospitalDetId = () => {
      props.setHospitalDetEdit(props.record.id);
  }
  return (
      <Button
          color="secondary" disableElevation
          size="medium"
          
          onClick={setHospitalDetId}
          startIcon={<EditIcon />}>Edit</Button>
  )
};

export const AdvisoryPanelEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  const [isaddTpa, setIsAddTpa] = useState(false);
  const [isEditTpa, setIsEditTpa] = useState(false);
  const [hospitalDetEditId, setHospitalDetEditId] = useState(0);
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const myclasses = myStyles({});
  const refresh = useRefresh();
   

  return (
    <React.Fragment>
    <Edit title="Advisory Edit" {...propsObj}>
    
   <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...propsObj} />}>
          <FormTab value="DETAILS" label="Advisory Detail">
          <TextInput validate={[required()]} source="name" label="Name" fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput validate={[required()]} source="nameHindi" label="Name Hindi " fullWidth={true} formClassName={classes.last_inline_input} /> 
          <TextInput validate={[required()]} source="designation" label="Designation " fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput validate={[required()]} source="designationHindi" label="Designation Hindi " fullWidth={true} formClassName={classes.last_inline_input} />
          <TextInput  multiline source="description" label="Description " fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput  multiline source="descriptionHindi" label="Description Hindi " fullWidth={true} formClassName={classes.last_inline_input} />
          
          <TextInput multiline label="Address 1" source="address1"  fullWidth={true} formClassName={classes.first_inline_input} /> 
          <TextInput  multiline label="Address 2" source="address2"   fullWidth={true} formClassName={classes.last_inline_input} /> 
          <FormDataConsumer>
              {formDataProps => (
                <Addressfields {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput source="emailAddress" label="Email Address" fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput source="landlineNumber" label="Landline Number" fullWidth={true} formClassName={classes.two_4_input} />
          <TextInput source="phonenumber" label="Phone Number" fullWidth={true} formClassName={classes.three_4_input} /> 
          <TextInput label="Zip Code" source="zipCode" fullWidth={true} formClassName={classes.last_4_input} />  

          <TextInput label="WebSite" source="webSite" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput label="Linkedin ID" source="linkedinId" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput label="fb  ID" source="fbid" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput label="twiter Id" source="twiterId" fullWidth={true} formClassName={classes.last_4_input} />
            

            <TextInput label="Whatsup Mobile No" source="whatsupMobileNo" fullWidth={true} formClassName={classes.one_4_input} />  

            <TextInput label="Area of Expertise" source="areaofExpertise" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput label="Education" source="education" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput  multiline  label="More description" source="moredescription" fullWidth={true} formClassName={classes.last_4_input} />
             
            <BooleanInput label="Is Active" source="isActive" fullWidth={true} /> 

            <ImageField source="url" title="Image" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="English "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>


         


          </FormTab>
        </TabbedForm>


    </Edit>
 
</React.Fragment>
  );
};
