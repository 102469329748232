import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField
}
    from 'react-admin';

export const StateList = props => (
    <List {...props}  title="State">
        <Datagrid rowClick="edit">
            
            <TextField source="name" />
            <TextField source="code" />
            <ReferenceField source="countryId" reference="countries" label="Country"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isActive" />
        </Datagrid>
    </List>
);