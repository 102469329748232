import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Section Name"
      source="sectionName~like"
      alwaysOn
    />
  </Filter>
);
export const ProductDiseaseList = (props) => {
 
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate =(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Product Disease List" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        <ReferenceField label="Product Group" source="productGroupId"
                  filter={{ type: 'FST' }}
                  reference="product-groups" link={false}>
                  <TextField source="groupName" />
                </ReferenceField>

      <ReferenceField  perPage={2000} label="Product" source="productId" reference="products" link={false}
      >
                    <TextField source="productName" />
      </ReferenceField> 

      <ReferenceField  perPage={2000} label="Stage" source="stageId" reference="stages"  link={false}
      >
                    <TextField source="name" />
      </ReferenceField> 
        <TextField source="diseaseName" label="Disease Name " />
        <TextField source="diseaseNameHindi" label="Disease Name Hindi" />
        

        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="ModifyBy" reference="users" label="Modify By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifyDate" label="Modify Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
