import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="UOM" source="name~like" alwaysOn />
    </Filter>
);
export const UomsList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate =(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    //<List {...props} title="UOM List" bulkActionButtons={false} filters={<Filters />}  >
      <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="UOM List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="name" label="Name" />
        <TextField source="nameHindi" label="Name Hindi" />
        
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="CreatedDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date"/>
      </Datagrid>
    </List>
  );
};
