import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../../common/formStyles";

const Facilityfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div >

            <ReferenceInput variant="outlined" label="Cost Center" source="compCostCenterId"
                reference="company-cost-centers"
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ "companyId": parseInt(rest.configId) }}
                fullWidth={true} perPage={5000} 
                onChange={(v) => {
                    form.change("compBranchId", 0);
                }} >
                <SelectInput optionText="name" validate={required()} />

            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Branch" source="compBranchId"
                reference="company-branches" perPage={500}
                filter={{ "compCostCenterId": formData.compCostCenterId }}
                validate={required()}

                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} >
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>
        </div>
    )
}
export default Facilityfields;
