import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField,
  NumberInput
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";

const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.url && record.url.length > 0)
    return (
      <a href={record.url}>View File</a>
    )
  else {
    return null
  }
}
const DisplayImage = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <div style={{width:"100px",height:"100px"}}> 
        <img                    src={record[source]}
                      width="100%"
                      height="100%"
                      
        /> </div>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
      display: "flex",
      justifyContent: "space-between",
  },
  item: {
      width: 100,
  },
}));

export const TPAEditButton = props => {
  const setHospitalDetId = () => {
      props.setHospitalDetEdit(props.record.id);
  }
  return (
      <Button
          color="secondary" disableElevation
          size="medium"
          
          onClick={setHospitalDetId}
          startIcon={<EditIcon />}>Edit</Button>
  )
};

export const SupplierEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  
  const [isAddress, setIsAddress] = useState(false);
  const [isLicense, setIsLicense] = useState(false);
  const [isImageType, setIsImageType] = useState(0);
  
  const myclasses = myStyles({});
  const refresh = useRefresh();
  
const closeEdit = () => {
  
  setIsAddress(false);
  setIsLicense(false);
  refresh();
}


const onCancel = () => {
  
  setIsAddress(false);
  setIsLicense(false);
}

  const handleClose = useCallback(() => {
    setIsAddress(false);
    setIsLicense(false);
    
}, []);

  return (
    <React.Fragment>
    <Edit title="Supplier Edit" {...propsObj}>
    
   <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...propsObj} />}>
          <FormTab value="DETAILS" label="Supplier Detail">
          
          <TextInput validate={[required()]} source="supplierName" label=" Name" fullWidth={true} formClassName={classes.one_three_input} />
          
          
          <TextInput  source="contactName" label="Contact Name " fullWidth={true} formClassName={classes.two_three_input} /> 
          
          <TextInput  source="mobilePhone" label="Mobile Phone " fullWidth={true} formClassName={classes.last_three_input} />

          <TextInput  source="officePhone" label="Office Phone " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="email" label="Email " fullWidth={true} formClassName={classes.two_three_input} />
          <SelectInput source="gstType" label="Tax Type" fullWidth={true} formClassName={classes.last_three_input} 
                    choices={[
                        { id: 1, name: 'Local' },
                        { id: 2, name: 'Center' }
                    ]} />
          <TextInput  source="gstNo" label="GST NO " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="bankAccountNo" label="Bank Account No " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="bankName" label="Bank Name " fullWidth={true} formClassName={classes.last_three_input} />
          <TextInput  source="bankAddress" label="Bank Address " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="ifscCode" label="IFSC Code " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="panNo" label="PAN No " fullWidth={true} formClassName={classes.last_three_input} />    
          <BooleanInput label="Is Active" source="isActive" fullWidth={true} formClassName={classes.one_three_input} />
          <div style={{minWidth:"100%"}}></div>
          <TextInput  source="bAddress1" label="Billing Address 1 " fullWidth={true} formClassName={classes.one_three_input} />    
          <TextInput  source="bAddress2" label="Billing Address 2 " fullWidth={true} formClassName={classes.two_three_input} />    
          <ReferenceInput label="Billing State" formClassName={classes.last_three_input} 
                    perPage={5000} source="bStateId" 
                    fullWidth={true}
                    reference="states" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.last_three_input}  />
          </ReferenceInput>
          <TextInput  source="bPincode" label="Billing Pincode " fullWidth={true} formClassName={classes.one_three_input} />        
          <div style={{minWidth:"100%"}}></div>
          <TextInput  source="sAddress1" label="Shipping Address 1 " fullWidth={true} formClassName={classes.one_three_input} />    
          <TextInput  source="sAddress2" label="Shipping Address 2 " fullWidth={true} formClassName={classes.two_three_input} />    
          <ReferenceInput label="Shipping State" formClassName={classes.last_three_input} 
                    perPage={5000} source="sStateId" 
                    fullWidth={true}
                    reference="states" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.last_three_input}  />
          </ReferenceInput>
          <TextInput  source="sPincode" label="Shipping Pincode " fullWidth={true} formClassName={classes.one_three_input} />        
              
          </FormTab>
          
        </TabbedForm>


    </Edit>
  
</React.Fragment>
  );
};
