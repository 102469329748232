import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import CreateToolbar from "../../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

import moment from 'moment';
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const CompanyCostCenterCreate = props => {
    const classes = useStyles({});
    let m = moment(new Date());
    const handleSubmit = async (formdata) => {
         console.log(formdata);
         let userid = localStorage.getItem("userId"); 
        let data = {
            "name":formdata.name,
            "nameHindi":formdata.name,
            "companyId": parseInt(props.configId),
            "stateId": parseInt(formdata.stateId),
            "taxTypeId": parseInt(formdata.taxTypeId),
            "taxNo":formdata.taxNo,
            address1:formdata.address1,
            address2:formdata.address2,
            zipCode:formdata.zipCode,
            "tin":formdata.tin,
            "isActive":formdata.isActive,   
            
        }
        data.createdBy = parseInt(userid);
        data.createdDate = m.toISOString();
        data.modifyBy = parseInt(userid);
        data.modifyDate = m.toISOString();
        
        apiClient.addEntiyRecord("company-cost-centers", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined">
                <TextInput validate={[required()]}  source="name" label="Cost Center Name" fullWidth={true} />
                <ReferenceInput variant="outlined" label="State" source="stateId" reference="states"
                 sort={{ field: 'name', order: 'ASC' }}
                
                fullWidth={true} perPage={5000} >
                <SelectInput optionText="name"  validate={required()}/>
            </ReferenceInput>      
            <ReferenceInput variant="outlined" label="Tax Type" source="taxTypeId" reference="tax-heads"
                 sort={{ field: 'taxName', order: 'ASC' }}
                validate={required()}
                fullWidth={true} perPage={5000} >
                <SelectInput optionText="taxName" validate={required()}/>
            </ReferenceInput>       
            <TextInput   source="taxNo" label="Tax No" fullWidth={true}  initialValue={""}/>
            <TextInput   source="tin" label="PAN No" fullWidth={true}   initialValue={""}/>
            <TextInput   source="address1" label="Address 1" fullWidth={true}  initialValue={""}/>
            <TextInput   source="address2" label="Address 2" fullWidth={true}   initialValue={""}/>
            <TextInput   source="zipCode" label="Pincode" fullWidth={true}   initialValue={""}/>
            <BooleanInput label="Is Active" source="isActive" fullWidth={true} />
            </SimpleForm>
        </Create>
    );
}
export default CompanyCostCenterCreate;