import React, { useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const DocumentsCreate = props => {
    const classes = useStyles({});
    const [isLoading,setIsLoading] = useState(false);
    const[sectionType,setSectionType]=useState(parseInt(props.sectionType));
    const initialValues = {
        calimOrdId: props.calimOrdId
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        setIsLoading(true);
        let m = moment(new Date());
        debugger
        if (sectionType!=113)
        {
            apiClient.UploadFileV1("imageUrl", formdata.imageUrl).then(res => {
                debugger
                let documentUrl = res;
                let data = {
                    cpId: parseInt(props.id),
                    isLinked:formdata.isLinked,
                    isActive:formdata.isActive,
                    propertyName: formdata.propertyName,
                    propertyNameHindi: formdata.propertyNameHindi,
                    order: formdata.order,  
                    imageUrl: documentUrl.url,
                    imageSize:parseInt(documentUrl.filesize)
                }
                apiClient.addEntiyRecord("content-page-config-dets", data).then(res => {
                    //console.log(res);
                    setIsLoading(false);
                    props.onClose();
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false);
                });
    
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    
        }
        else
        {
            let data = {
                cpId: parseInt(props.id),
                isLinked:formdata.isLinked,
                isActive:formdata.isActive,
                propertyName: formdata.propertyName,
                propertyNameHindi: formdata.propertyNameHindi,
                order: formdata.order,  
                imageUrl: formdata.imageUrl,
                imageSize:0
            }
            apiClient.addEntiyRecord("content-page-config-dets", data).then(res => {
                //console.log(res);
                setIsLoading(false);
                props.onClose();
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    
        }
        
    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar loading={isLoading} label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">
                    
                {sectionType!=113?<FileInput fullWidth={ true } source="imageUrl" label="Image"> 
                <ImageField source="imageUrl" title="File" />  
                </FileInput> 
                :<TextInput source="imageUrl" label="Video Url" fullWidth={ true } validate={[required()]}  />}

                <TextInput source="propertyName" label="Property Name" fullWidth={ true } validate={[required()]}  />
                <TextInput source="propertyNameHindi" label="Property Name Hindi" fullWidth={ true }  validate={[required()]}  />
                <NumberInput source="order" label="order" fullWidth={ true }  validate={[required()]}  />
                <BooleanInput source="isLinked" validate={[required()]}  label="Linked" initialValue={false} />
                <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} />
                
            </SimpleForm>
        </Create>
    );
}
export default DocumentsCreate;