import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField,
  ImageInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

export const ProductCategoryEdit = (props) => {
  const classes = useStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  
  return (
    <Edit {...propsObj}  title="Product Category Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <ReferenceInput  perPage={2000} label="Product Type" source="productTypeId" reference="product-types" 
      fullWidth={true} sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" validate={required()}  disabled={false}/>
                </ReferenceInput> 
       <TextInput source="cateName" validate={[required()]} label="English Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="cateNameHindi" validate={[required()]} label="Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       
       <ImageField source="url" title="English Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="English "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>

         <ImageField source="urlHindi" title="Hindi Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="hindiUpurl" 
              formClassName={classes.last_inline_input}
              label="Hindi "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="hindiUpurl" title="Icon" />
            </ImageInput>
       
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />

      </SimpleForm>
    </Edit>
  );
};
