import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  FileInput, FileField, ReferenceInput ,SelectInput,DateTimeInput ,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const NotifiactionCreate = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  const initialValues = {
    
    // code:"-",
    // url:"",
    // urlHindi:"",
    // sourceUrl:""
}
  return (
    <Create   {...propsObj} title="Notification Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={initialValues}> 
            
       <TextInput source="name" validate={[required()]} label="English Name" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput source="nameHindi" validate={[required()]} label="Hindi Name" fullWidth={true} formClassName={classes.last_inline_input}/>
       <TextInput multiline source="description" label="English Desciption" fullWidth={true}  formClassName={classes.first_inline_input}/>
       <TextInput multiline source="descriptionHindi"  label="Hindi Desciption" fullWidth={true}  formClassName={classes.last_inline_input}/> 
       <DateTimeInput source="triggerDatetime" validate={[required()]} label="Trigger Date time" fullWidth={true} formClassName={classes.first_inline_input}/>  
       <BooleanInput source="isActive" validate={[required()]}  label="Active" fullWidth={true}  formClassName={classes.last_inline_input}/> 
      </SimpleForm>
    </Create>
  );
};
