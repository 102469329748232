




import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  FileInput, FileField, SelectInput,
  ReferenceInput,
  BooleanInput,
  required
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const CountryEdit = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const classes = useStyles({});

  return (
    <Edit   {...propsObj} title="Country Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <TextInput validate={required()} source="name" label="Name" fullWidth={true} />
      <TextInput validate={required()} source="nameHindi" label="Name Hindi" fullWidth={true} />
                <TextInput validate={required()} source="code" label="Code" fullWidth={true} />
                <BooleanInput initialValue={true} source="isActive" />
      </SimpleForm>
    </Edit>
  );
};
