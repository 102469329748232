import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const CityEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} title="City">
            <SimpleForm redirect="list"  
            variant="outlined">
           <TextInput validate={required()} source="code" label="City Code" fullWidth={true} />
            <TextInput validate={required()} source="name" label="City Name" fullWidth={true} />
            <TextInput validate={required()} source="nameHindi" label="City Name Hindi" fullWidth={true} />
            <ReferenceInput source="stateId" reference="states"  fullWidth={true} >
                <SelectInput optionText="name"  fullWidth={true} />
            </ReferenceInput>
            <BooleanInput initialValue={true} source="isActive" />
            </SimpleForm>
        </Edit>
    );
}